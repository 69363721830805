import { DashboardCard } from "./DashboardCard";
import { GridContainer } from "../Layout/GridContainer";
import { GridItem } from "../Layout/GridItem";
import { Caption } from "../Typography/Typography";
import { styled } from "../../styles";

export type StatItem = {
  label: string;
  css?: object;
  value: string;
  superscript?: {
    text: string;
    css?: any;
  };
};

export interface StatsCardProps {
  title: string;
  stats: StatItem[];
  itemWidth?: string | number;
}

const StatsCardTitle = styled(Caption, {
  color: "$darkShade5",
  display: "flex",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",
  margin: "$1 0 $2",
});

const StatsCardValue = styled(Caption, {
  color: "$textActive",
  fontFamily: "$headings",
  fontSize: "24px",
  fontWeight: 500,
});

const Superscript = styled("sup", {
  fontSize: "10px",
});

export const StatsCard = ({ stats, title, itemWidth = 6 }: StatsCardProps) => {
  return (
    <DashboardCard title={title}>
      <GridContainer css={{ marginTop: "$2" }}>
        {Array.isArray(stats) &&
          stats.map((stat) => (
            <GridItem
              key={stat.label}
              desktop={itemWidth}
              tablet={itemWidth}
              mobile={itemWidth}
            >
              <StatsCardTitle>{stat.label}</StatsCardTitle>

              <StatsCardValue css={{ ...(stat.css || {}) }}>
                {stat.value}

                {stat.superscript?.text && (
                  <Superscript css={stat.superscript?.css || {}}>
                    {stat.superscript.text}
                  </Superscript>
                )}
              </StatsCardValue>
            </GridItem>
          ))}
      </GridContainer>
    </DashboardCard>
  );
};
